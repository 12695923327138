@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hmIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hvIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hnIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hoIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hkIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hlIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local("OpenSans-LightItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqM.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWyV9hrIqU.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Udc1UAw.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0ddc1UAw.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Vdc1UAw.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0adc1UAw.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Wdc1UAw.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Xdc1UAw.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local("OpenSans-Italic"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdc0.woff2") format("woff2"),url("../fonts/mem6YaGs126MiZpBA-UFUK0Zdcs.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhmIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhvIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhnIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhoIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhkIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhlIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local("OpenSans-SemiBoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqM.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKXGUdhrIqU.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhmIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhvIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhnIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhoIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhkIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhlIqOjjg.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local("OpenSans-BoldItalic"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqM.woff2") format("woff2"),url("../fonts/memnYaGs126MiZpBA-UFUKWiUNhrIqU.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OX-hpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OVuhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OXuhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUehpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OXehpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OXOhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local("OpenSans-Light"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFWJ0bbck.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFUZ0bbck.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFWZ0bbck.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFVp0bbck.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFWp0bbck.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFW50bbck.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local("OpenSans-Regular"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0b.woff2") format("woff2"),url("../fonts/mem8YaGs126MiZpBA-UFVZ0d.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local("OpenSans-SemiBold"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhp.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOX-hpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOVuhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOXuhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUehpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOXehpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local("OpenSans-Bold"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2") format("woff2"),url("../fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff") format("woff");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2") format("woff2");
  unicode-range: U+0460-052F,U+1C80-1C8A,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2") format("woff2");
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2") format("woff2");
  unicode-range: U+0370-0377,U+037A-037F,U+0384-038A,U+038C,U+038E-03A1,U+03A3-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2") format("woff2");
  unicode-range: U+0307-0308,U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWxU6F15M.woff2") format("woff2");
  unicode-range: U+0302-0303,U+0305,U+0307-0308,U+0310,U+0312,U+0315,U+031A,U+0326-0327,U+032C,U+032F-0330,U+0332-0333,U+0338,U+033A,U+0346,U+034D,U+0391-03A1,U+03A3-03A9,U+03B1-03C9,U+03D1,U+03D5-03D6,U+03F0-03F1,U+03F4-03F5,U+2016-2017,U+2034-2038,U+203C,U+2040,U+2043,U+2047,U+2050,U+2057,U+205F,U+2070-2071,U+2074-208E,U+2090-209C,U+20D0-20DC,U+20E1,U+20E5-20EF,U+2100-2112,U+2114-2115,U+2117-2121,U+2123-214F,U+2190,U+2192,U+2194-21AE,U+21B0-21E5,U+21F1-21F2,U+21F4-2211,U+2213-2214,U+2216-22FF,U+2308-230B,U+2310,U+2319,U+231C-2321,U+2336-237A,U+237C,U+2395,U+239B-23B7,U+23D0,U+23DC-23E1,U+2474-2475,U+25AF,U+25B3,U+25B7,U+25BD,U+25C1,U+25CA,U+25CC,U+25FB,U+266D-266F,U+27C0-27FF,U+2900-2AFF,U+2B0E-2B11,U+2B30-2B4C,U+2BFE,U+3030,U+FF5B,U+FF5D,U+1D400-1D7FF,U+1EE00-1EEFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqW106F15M.woff2") format("woff2");
  unicode-range: U+0001-000C,U+000E-001F,U+007F-009F,U+20DD-20E0,U+20E2-20E4,U+2150-218F,U+2190,U+2192,U+2194-2199,U+21AF,U+21E6-21F0,U+21F3,U+2218-2219,U+2299,U+22C4-22C6,U+2300-243F,U+2440-244A,U+2460-24FF,U+25A0-27BF,U+2800-28FF,U+2921-2922,U+2981,U+29BF,U+29EB,U+2B00-2BFF,U+4DC0-4DFF,U+FFF9-FFFB,U+10140-1018E,U+10190-1019C,U+101A0,U+101D0-101FD,U+102E0-102FB,U+10E60-10E7E,U+1D2C0-1D2D3,U+1D2E0-1D37F,U+1F000-1F0FF,U+1F100-1F1AD,U+1F1E6-1F1FF,U+1F30D-1F30F,U+1F315,U+1F31C,U+1F31E,U+1F320-1F32C,U+1F336,U+1F378,U+1F37D,U+1F382,U+1F393-1F39F,U+1F3A7-1F3A8,U+1F3AC-1F3AF,U+1F3C2,U+1F3C4-1F3C6,U+1F3CA-1F3CE,U+1F3D4-1F3E0,U+1F3ED,U+1F3F1-1F3F3,U+1F3F5-1F3F7,U+1F408,U+1F415,U+1F41F,U+1F426,U+1F43F,U+1F441-1F442,U+1F444,U+1F446-1F449,U+1F44C-1F44E,U+1F453,U+1F46A,U+1F47D,U+1F4A3,U+1F4B0,U+1F4B3,U+1F4B9,U+1F4BB,U+1F4BF,U+1F4C8-1F4CB,U+1F4D6,U+1F4DA,U+1F4DF,U+1F4E3-1F4E6,U+1F4EA-1F4ED,U+1F4F7,U+1F4F9-1F4FB,U+1F4FD-1F4FE,U+1F503,U+1F507-1F50B,U+1F50D,U+1F512-1F513,U+1F53E-1F54A,U+1F54F-1F5FA,U+1F610,U+1F650-1F67F,U+1F687,U+1F68D,U+1F691,U+1F694,U+1F698,U+1F6AD,U+1F6B2,U+1F6B9-1F6BA,U+1F6BC,U+1F6C6-1F6CF,U+1F6D3-1F6D7,U+1F6E0-1F6EA,U+1F6F0-1F6F3,U+1F6F7-1F6FC,U+1F700-1F7FF,U+1F800-1F80B,U+1F810-1F847,U+1F850-1F859,U+1F860-1F887,U+1F890-1F8AD,U+1F8B0-1F8BB,U+1F8C0-1F8C1,U+1F900-1F90B,U+1F93B,U+1F946,U+1F984,U+1F996,U+1F9E9,U+1FA00-1FA6F,U+1FA70-1FA7C,U+1FA80-1FA89,U+1FA8F-1FAC6,U+1FACE-1FADC,U+1FADF-1FAE9,U+1FAF0-1FAF8,U+1FB00-1FBFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2") format("woff2");
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2") format("woff2");
  unicode-range: U+0100-02BA,U+02BD-02C5,U+02C7-02CC,U+02CE-02D7,U+02DD-02FF,U+0304,U+0308,U+0329,U+1D00-1DBF,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2") format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2") format("woff2");
  unicode-range: U+0460-052F,U+1C80-1C8A,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2") format("woff2");
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2") format("woff2");
  unicode-range: U+0370-0377,U+037A-037F,U+0384-038A,U+038C,U+038E-03A1,U+03A3-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2") format("woff2");
  unicode-range: U+0307-0308,U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWxU6F15M.woff2") format("woff2");
  unicode-range: U+0302-0303,U+0305,U+0307-0308,U+0310,U+0312,U+0315,U+031A,U+0326-0327,U+032C,U+032F-0330,U+0332-0333,U+0338,U+033A,U+0346,U+034D,U+0391-03A1,U+03A3-03A9,U+03B1-03C9,U+03D1,U+03D5-03D6,U+03F0-03F1,U+03F4-03F5,U+2016-2017,U+2034-2038,U+203C,U+2040,U+2043,U+2047,U+2050,U+2057,U+205F,U+2070-2071,U+2074-208E,U+2090-209C,U+20D0-20DC,U+20E1,U+20E5-20EF,U+2100-2112,U+2114-2115,U+2117-2121,U+2123-214F,U+2190,U+2192,U+2194-21AE,U+21B0-21E5,U+21F1-21F2,U+21F4-2211,U+2213-2214,U+2216-22FF,U+2308-230B,U+2310,U+2319,U+231C-2321,U+2336-237A,U+237C,U+2395,U+239B-23B7,U+23D0,U+23DC-23E1,U+2474-2475,U+25AF,U+25B3,U+25B7,U+25BD,U+25C1,U+25CA,U+25CC,U+25FB,U+266D-266F,U+27C0-27FF,U+2900-2AFF,U+2B0E-2B11,U+2B30-2B4C,U+2BFE,U+3030,U+FF5B,U+FF5D,U+1D400-1D7FF,U+1EE00-1EEFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqW106F15M.woff2") format("woff2");
  unicode-range: U+0001-000C,U+000E-001F,U+007F-009F,U+20DD-20E0,U+20E2-20E4,U+2150-218F,U+2190,U+2192,U+2194-2199,U+21AF,U+21E6-21F0,U+21F3,U+2218-2219,U+2299,U+22C4-22C6,U+2300-243F,U+2440-244A,U+2460-24FF,U+25A0-27BF,U+2800-28FF,U+2921-2922,U+2981,U+29BF,U+29EB,U+2B00-2BFF,U+4DC0-4DFF,U+FFF9-FFFB,U+10140-1018E,U+10190-1019C,U+101A0,U+101D0-101FD,U+102E0-102FB,U+10E60-10E7E,U+1D2C0-1D2D3,U+1D2E0-1D37F,U+1F000-1F0FF,U+1F100-1F1AD,U+1F1E6-1F1FF,U+1F30D-1F30F,U+1F315,U+1F31C,U+1F31E,U+1F320-1F32C,U+1F336,U+1F378,U+1F37D,U+1F382,U+1F393-1F39F,U+1F3A7-1F3A8,U+1F3AC-1F3AF,U+1F3C2,U+1F3C4-1F3C6,U+1F3CA-1F3CE,U+1F3D4-1F3E0,U+1F3ED,U+1F3F1-1F3F3,U+1F3F5-1F3F7,U+1F408,U+1F415,U+1F41F,U+1F426,U+1F43F,U+1F441-1F442,U+1F444,U+1F446-1F449,U+1F44C-1F44E,U+1F453,U+1F46A,U+1F47D,U+1F4A3,U+1F4B0,U+1F4B3,U+1F4B9,U+1F4BB,U+1F4BF,U+1F4C8-1F4CB,U+1F4D6,U+1F4DA,U+1F4DF,U+1F4E3-1F4E6,U+1F4EA-1F4ED,U+1F4F7,U+1F4F9-1F4FB,U+1F4FD-1F4FE,U+1F503,U+1F507-1F50B,U+1F50D,U+1F512-1F513,U+1F53E-1F54A,U+1F54F-1F5FA,U+1F610,U+1F650-1F67F,U+1F687,U+1F68D,U+1F691,U+1F694,U+1F698,U+1F6AD,U+1F6B2,U+1F6B9-1F6BA,U+1F6BC,U+1F6C6-1F6CF,U+1F6D3-1F6D7,U+1F6E0-1F6EA,U+1F6F0-1F6F3,U+1F6F7-1F6FC,U+1F700-1F7FF,U+1F800-1F80B,U+1F810-1F847,U+1F850-1F859,U+1F860-1F887,U+1F890-1F8AD,U+1F8B0-1F8BB,U+1F8C0-1F8C1,U+1F900-1F90B,U+1F93B,U+1F946,U+1F984,U+1F996,U+1F9E9,U+1FA00-1FA6F,U+1FA70-1FA7C,U+1FA80-1FA89,U+1FA8F-1FAC6,U+1FACE-1FADC,U+1FADF-1FAE9,U+1FAF0-1FAF8,U+1FB00-1FBFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2") format("woff2");
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2") format("woff2");
  unicode-range: U+0100-02BA,U+02BD-02C5,U+02C7-02CC,U+02CE-02D7,U+02DD-02FF,U+0304,U+0308,U+0329,U+1D00-1DBF,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2") format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2") format("woff2");
  unicode-range: U+0460-052F,U+1C80-1C8A,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2") format("woff2");
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2") format("woff2");
  unicode-range: U+0370-0377,U+037A-037F,U+0384-038A,U+038C,U+038E-03A1,U+03A3-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2") format("woff2");
  unicode-range: U+0307-0308,U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTVOmu1aB.woff2") format("woff2");
  unicode-range: U+0302-0303,U+0305,U+0307-0308,U+0310,U+0312,U+0315,U+031A,U+0326-0327,U+032C,U+032F-0330,U+0332-0333,U+0338,U+033A,U+0346,U+034D,U+0391-03A1,U+03A3-03A9,U+03B1-03C9,U+03D1,U+03D5-03D6,U+03F0-03F1,U+03F4-03F5,U+2016-2017,U+2034-2038,U+203C,U+2040,U+2043,U+2047,U+2050,U+2057,U+205F,U+2070-2071,U+2074-208E,U+2090-209C,U+20D0-20DC,U+20E1,U+20E5-20EF,U+2100-2112,U+2114-2115,U+2117-2121,U+2123-214F,U+2190,U+2192,U+2194-21AE,U+21B0-21E5,U+21F1-21F2,U+21F4-2211,U+2213-2214,U+2216-22FF,U+2308-230B,U+2310,U+2319,U+231C-2321,U+2336-237A,U+237C,U+2395,U+239B-23B7,U+23D0,U+23DC-23E1,U+2474-2475,U+25AF,U+25B3,U+25B7,U+25BD,U+25C1,U+25CA,U+25CC,U+25FB,U+266D-266F,U+27C0-27FF,U+2900-2AFF,U+2B0E-2B11,U+2B30-2B4C,U+2BFE,U+3030,U+FF5B,U+FF5D,U+1D400-1D7FF,U+1EE00-1EEFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTUGmu1aB.woff2") format("woff2");
  unicode-range: U+0001-000C,U+000E-001F,U+007F-009F,U+20DD-20E0,U+20E2-20E4,U+2150-218F,U+2190,U+2192,U+2194-2199,U+21AF,U+21E6-21F0,U+21F3,U+2218-2219,U+2299,U+22C4-22C6,U+2300-243F,U+2440-244A,U+2460-24FF,U+25A0-27BF,U+2800-28FF,U+2921-2922,U+2981,U+29BF,U+29EB,U+2B00-2BFF,U+4DC0-4DFF,U+FFF9-FFFB,U+10140-1018E,U+10190-1019C,U+101A0,U+101D0-101FD,U+102E0-102FB,U+10E60-10E7E,U+1D2C0-1D2D3,U+1D2E0-1D37F,U+1F000-1F0FF,U+1F100-1F1AD,U+1F1E6-1F1FF,U+1F30D-1F30F,U+1F315,U+1F31C,U+1F31E,U+1F320-1F32C,U+1F336,U+1F378,U+1F37D,U+1F382,U+1F393-1F39F,U+1F3A7-1F3A8,U+1F3AC-1F3AF,U+1F3C2,U+1F3C4-1F3C6,U+1F3CA-1F3CE,U+1F3D4-1F3E0,U+1F3ED,U+1F3F1-1F3F3,U+1F3F5-1F3F7,U+1F408,U+1F415,U+1F41F,U+1F426,U+1F43F,U+1F441-1F442,U+1F444,U+1F446-1F449,U+1F44C-1F44E,U+1F453,U+1F46A,U+1F47D,U+1F4A3,U+1F4B0,U+1F4B3,U+1F4B9,U+1F4BB,U+1F4BF,U+1F4C8-1F4CB,U+1F4D6,U+1F4DA,U+1F4DF,U+1F4E3-1F4E6,U+1F4EA-1F4ED,U+1F4F7,U+1F4F9-1F4FB,U+1F4FD-1F4FE,U+1F503,U+1F507-1F50B,U+1F50D,U+1F512-1F513,U+1F53E-1F54A,U+1F54F-1F5FA,U+1F610,U+1F650-1F67F,U+1F687,U+1F68D,U+1F691,U+1F694,U+1F698,U+1F6AD,U+1F6B2,U+1F6B9-1F6BA,U+1F6BC,U+1F6C6-1F6CF,U+1F6D3-1F6D7,U+1F6E0-1F6EA,U+1F6F0-1F6F3,U+1F6F7-1F6FC,U+1F700-1F7FF,U+1F800-1F80B,U+1F810-1F847,U+1F850-1F859,U+1F860-1F887,U+1F890-1F8AD,U+1F8B0-1F8BB,U+1F8C0-1F8C1,U+1F900-1F90B,U+1F93B,U+1F946,U+1F984,U+1F996,U+1F9E9,U+1FA00-1FA6F,U+1FA70-1FA7C,U+1FA80-1FA89,U+1FA8F-1FAC6,U+1FACE-1FADC,U+1FADF-1FAE9,U+1FAF0-1FAF8,U+1FB00-1FBFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2") format("woff2");
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2") format("woff2");
  unicode-range: U+0100-02BA,U+02BD-02C5,U+02C7-02CC,U+02CE-02D7,U+02DD-02FF,U+0304,U+0308,U+0329,U+1D00-1DBF,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2") format("woff2");
  unicode-range: U+0460-052F,U+1C80-1C8A,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2") format("woff2");
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2") format("woff2");
  unicode-range: U+0370-0377,U+037A-037F,U+0384-038A,U+038C,U+038E-03A1,U+03A3-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2") format("woff2");
  unicode-range: U+0307-0308,U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTVOmu1aB.woff2") format("woff2");
  unicode-range: U+0302-0303,U+0305,U+0307-0308,U+0310,U+0312,U+0315,U+031A,U+0326-0327,U+032C,U+032F-0330,U+0332-0333,U+0338,U+033A,U+0346,U+034D,U+0391-03A1,U+03A3-03A9,U+03B1-03C9,U+03D1,U+03D5-03D6,U+03F0-03F1,U+03F4-03F5,U+2016-2017,U+2034-2038,U+203C,U+2040,U+2043,U+2047,U+2050,U+2057,U+205F,U+2070-2071,U+2074-208E,U+2090-209C,U+20D0-20DC,U+20E1,U+20E5-20EF,U+2100-2112,U+2114-2115,U+2117-2121,U+2123-214F,U+2190,U+2192,U+2194-21AE,U+21B0-21E5,U+21F1-21F2,U+21F4-2211,U+2213-2214,U+2216-22FF,U+2308-230B,U+2310,U+2319,U+231C-2321,U+2336-237A,U+237C,U+2395,U+239B-23B7,U+23D0,U+23DC-23E1,U+2474-2475,U+25AF,U+25B3,U+25B7,U+25BD,U+25C1,U+25CA,U+25CC,U+25FB,U+266D-266F,U+27C0-27FF,U+2900-2AFF,U+2B0E-2B11,U+2B30-2B4C,U+2BFE,U+3030,U+FF5B,U+FF5D,U+1D400-1D7FF,U+1EE00-1EEFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTUGmu1aB.woff2") format("woff2");
  unicode-range: U+0001-000C,U+000E-001F,U+007F-009F,U+20DD-20E0,U+20E2-20E4,U+2150-218F,U+2190,U+2192,U+2194-2199,U+21AF,U+21E6-21F0,U+21F3,U+2218-2219,U+2299,U+22C4-22C6,U+2300-243F,U+2440-244A,U+2460-24FF,U+25A0-27BF,U+2800-28FF,U+2921-2922,U+2981,U+29BF,U+29EB,U+2B00-2BFF,U+4DC0-4DFF,U+FFF9-FFFB,U+10140-1018E,U+10190-1019C,U+101A0,U+101D0-101FD,U+102E0-102FB,U+10E60-10E7E,U+1D2C0-1D2D3,U+1D2E0-1D37F,U+1F000-1F0FF,U+1F100-1F1AD,U+1F1E6-1F1FF,U+1F30D-1F30F,U+1F315,U+1F31C,U+1F31E,U+1F320-1F32C,U+1F336,U+1F378,U+1F37D,U+1F382,U+1F393-1F39F,U+1F3A7-1F3A8,U+1F3AC-1F3AF,U+1F3C2,U+1F3C4-1F3C6,U+1F3CA-1F3CE,U+1F3D4-1F3E0,U+1F3ED,U+1F3F1-1F3F3,U+1F3F5-1F3F7,U+1F408,U+1F415,U+1F41F,U+1F426,U+1F43F,U+1F441-1F442,U+1F444,U+1F446-1F449,U+1F44C-1F44E,U+1F453,U+1F46A,U+1F47D,U+1F4A3,U+1F4B0,U+1F4B3,U+1F4B9,U+1F4BB,U+1F4BF,U+1F4C8-1F4CB,U+1F4D6,U+1F4DA,U+1F4DF,U+1F4E3-1F4E6,U+1F4EA-1F4ED,U+1F4F7,U+1F4F9-1F4FB,U+1F4FD-1F4FE,U+1F503,U+1F507-1F50B,U+1F50D,U+1F512-1F513,U+1F53E-1F54A,U+1F54F-1F5FA,U+1F610,U+1F650-1F67F,U+1F687,U+1F68D,U+1F691,U+1F694,U+1F698,U+1F6AD,U+1F6B2,U+1F6B9-1F6BA,U+1F6BC,U+1F6C6-1F6CF,U+1F6D3-1F6D7,U+1F6E0-1F6EA,U+1F6F0-1F6F3,U+1F6F7-1F6FC,U+1F700-1F7FF,U+1F800-1F80B,U+1F810-1F847,U+1F850-1F859,U+1F860-1F887,U+1F890-1F8AD,U+1F8B0-1F8BB,U+1F8C0-1F8C1,U+1F900-1F90B,U+1F93B,U+1F946,U+1F984,U+1F996,U+1F9E9,U+1FA00-1FA6F,U+1FA70-1FA7C,U+1FA80-1FA89,U+1FA8F-1FAC6,U+1FACE-1FADC,U+1FADF-1FAE9,U+1FAF0-1FAF8,U+1FB00-1FBFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2") format("woff2");
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2") format("woff2");
  unicode-range: U+0100-02BA,U+02BD-02C5,U+02C7-02CC,U+02CE-02D7,U+02DD-02FF,U+0304,U+0308,U+0329,U+1D00-1DBF,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url("../fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

